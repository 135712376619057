import React, { useEffect, useState } from "react";
import Classes from "./payout.module.scss";
import Leaf from "../../assets/images/icons/yelowLeaf.svg";
import { Switch } from "antd";
import ConfirmModal from "../../models/ConfirmModal";
import { getAllSettings } from "../../api/services/settings";
import { toast } from "react-toastify";

import useSetPayoutPermission from "../../contract/hooks/stake/useSetPayoutPermission";
import useGetPayoutPermissionStatus from "../../contract/hooks/stake/useGetPayoutPermissionStatus";
// import useUpdateInterval from "../../contract/hooks/stake/useUpdatePayoutInterval";
function PayoutPermission() {
  const [payoutStatus, setPayoutStatus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    type: null,
    message: null,
    key: null,
    value: null,
  });
  const {
    payoutPermission,
    isPayoutPermissionTxInProgress,
    isPayoutPermissionTxSent,
    isPayoutPermissionWaiting,
    isPayoutPermissionCompleted,
  } = useSetPayoutPermission({setLoader});

  useEffect(()=>{
    if(isPayoutPermissionCompleted){
        setLoader(false)
        setIsModalOpen(false)
        setModalInfo({
            type: null,
            message: null,
            key: null,
            value: null,
          })
        toast.success("Payout Permission Updated Successfully")
    }
  },[isPayoutPermissionCompleted])

  const {payoutPermissionStatus}=useGetPayoutPermissionStatus()
  console.log(payoutPermissionStatus,'payoutPermissionStatus=>')
  useEffect(()=>{
      setPayoutStatus(payoutPermissionStatus)
  },[payoutPermissionStatus])

  const handleSwitchChange = (checked) => {
    setPayoutStatus(checked);
    setModalInfo({
      type: "status",
      message: `Are you sure you want to ${checked ? "Enable" : "Disable"} payout permission?`,
      key: "payoutPermission",
      value: checked ,
    });
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setPayoutStatus(payoutPermissionStatus)
    setIsModalOpen(false);
  };

  const onChangeStatus = (value) => {
    setPayoutStatus(value);
  };
  const onRejectInterval = () => {
    setIsModalOpen(false);
    setLoader(false);
    setModalInfo({
      type: null,
      message: null,
      key: null,
      value: null,
    });
    setPayoutStatus(payoutPermissionStatus)
  };

  return (
    <>
      <div className={Classes.payout}>
        <div className={Classes.content}>
          <div className="d-flex align-items-center gap-2 mb-4 ps-3">
            <img src={Leaf} alt="icon" />
            <p className={Classes.title}>Payout</p>
          </div>

          <div className={`${Classes.card} mx-3`}>
            <h2 className={Classes.cardtitle}>Payout Permission</h2>
            <div className="d-flex align-items-center gap-4 mb-4">
       
                <>
                  <p className={Classes.txt}>Enable / Disable</p>
                  <Switch
                    onClick={handleSwitchChange}
                    onChange={onChangeStatus}
                    value={payoutStatus}
                  />
                </>
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        isModalOpen={isModalOpen}
        loader={loader}
        modalInfo={modalInfo}
        handleCancel={handleCancel}
        onConfirm={()=>{
            setLoader(true)
            payoutPermission(
                { args: [modalInfo?.value] }
            )
            console.log(modalInfo?.value,'modalInfo?.value=>')
        }}
        onReject={() => {
          onRejectInterval();
        }}
      />
    </>
  );
}

export default PayoutPermission;
