import React, { useEffect, useState } from "react";
import Classes from "./sidebar.module.scss";
import { Menu } from "antd";
import Logo from "../../../assets/images/section/logo.svg";
import { ReactComponent as DashboardIcon } from "../../../assets/images/icons/dashboard.svg";
import { ReactComponent as Bot } from "../../../assets/images/icons/bot.svg";
import { ReactComponent as Add } from "../../../assets/images/icons/add.svg";
import { ReactComponent as Swap } from "../../../assets/images/icons/swap.svg";
import { ReactComponent as User } from "../../../assets/images/icons/user.svg";
import { ReactComponent as Polls } from "../../../assets/images/icons/investment.svg";
import { ReactComponent as Deduction } from "../../../assets/images/icons/price.svg";
import { ReactComponent as Protocol } from "../../../assets/images/icons/edit.svg";


import { Link, useLocation } from "react-router-dom";

const SidebarComponent = ({ collapsed }) => {
  const [showName, setShowName] = useState(false);
  const [selectedKey, setSelectedKey] = useState("");
  const location = useLocation(); // to track the current route

  useEffect(() => {
    // Retrieve the selected key from localStorage when the component mounts
    const storedKey = localStorage.getItem("selectedKey");
    if (location.pathname) {
      const routeKey = menuItems.find(item => item.label.props.to === location.pathname)?.key;
      if (routeKey) {
        setSelectedKey(routeKey);
      }else {
        setSelectedKey("1")
      }
      
    } else {
      setSelectedKey(storedKey)
    }
  }, [location.pathname]);

  useEffect(() => {
    let timeoutId;
    if (!collapsed) {
      timeoutId = setTimeout(() => {
        setShowName(true);
      }, 100);
    } else {
      setShowName(false);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [collapsed]);

  const handleMenuSelect = (e) => {
    const { key } = e;
    setSelectedKey(key);
    // Save the selected key to localStorage
    localStorage.setItem("selectedKey", key);
  };

  const menuItems = [
    {
      key: "1",
      icon: <DashboardIcon />,
      label: <Link to="/">Dashboard</Link>,
      title: null,
    },
    // Uncomment if you plan to use this later
    // {
    //   key: "2",
    //   icon: <Bot className="bot" />,
    //   label: <Link to="/bot">DAO BOT</Link>,
    //   title: null,
    // },
    {
      key: "3",
      icon: <Swap />,
      label: <Link to="/percentage-changes">Percentage Changes</Link>,
      title: null,
    },
    {
      key: "4",
      icon: <Add />,
      label: <Link to="/add-funds">Add Funds</Link>,
      title: null,
    },
    {
      key: "5",
      icon: <Add />,
      label: <Link to="/add-project">Add Project</Link>,
      title: null,
    },
    {
      key: "6",
      icon: <User />,
      label: <Link to="/users">Users</Link>,
      title: null,
    },
    {
      key: "7",
      icon: <Polls />,
      label: <Link to="/payout">Payout</Link>,
      title: null,
    },
    {
      key: "8",
      icon: <Add />,
      label: <Link to="/pre-investment">Pre-Investment</Link>,
      title: null,
    },
    {
      key: "9",
      icon: <Deduction />,
      label: <Link to="/deduct-user-amount">Deduct User Amount</Link>,
      title: null,
    },
    {
      key: "10",
      icon: <Protocol />,
      label: <Link to="/payout-permission">Payout Permission</Link>,
      title: null,
    },
  ];
  return (
    <aside className={Classes.sidebar}>
      <div
        className={`${Classes.logo} d-flex align-items-center gap-1 pb-3 px-3`}
      >
        <img src={Logo} alt="logo" />
        {showName && <p className={Classes.name}>The Warriors DAO</p>}
      </div>
      <Menu
        selectedKeys={[selectedKey]} // Set the selected key from state
        onSelect={handleMenuSelect} // Handle selection to save the key in localStorage
        className="px-3"
        items={menuItems}
      />
    </aside>
  );
};

export default SidebarComponent;
