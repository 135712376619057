// ** WEB3 Imports
import { useState, useEffect } from "react";
import { useContractRead } from "wagmi";
import { CONTRACT_INFO } from "../../index";

const useGetPayoutPermissionStatus = () => {
  const [payoutPermissionStatus, setPayoutPermissionStatus] = useState(null);

  const {
    data: permission,
    refetch,
  } = useContractRead({
    address: CONTRACT_INFO.stake.address,
    abi: CONTRACT_INFO.stake.abi,
    functionName: "permission", // Update if function name is different for payout permission
  });

  useEffect(() => {
      setPayoutPermissionStatus(permission);

  }, [permission]);

  return {
    payoutPermissionStatus,
    refetch,
  };
};

export default useGetPayoutPermissionStatus;
