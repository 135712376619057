import { useContractWrite, useWaitForTransaction } from "wagmi";
import { CONTRACT_INFO } from "../../index";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { extractErrorMessage } from "../../../helpers/common";

const address = CONTRACT_INFO.stake.address;
const abi = CONTRACT_INFO.stake.abi;

const useSetPayoutPermission = ({ setLoader }) => {
  const {
    isError: isPayoutPermissionSentError,
    data: payoutPermissionSentTx,
    isLoading: isPayoutPermissionTxInProgress,
    isSuccess: isPayoutPermissionTxSent,
    write: payoutPermission, // Function to interact with the payoutPermission contract
    error: payoutPermissionSentError
  } = useContractWrite({
    address,
    abi,
    functionName: "setPermission", // Assuming this function remains the same in your ABI
  });

  const {
    isLoading: isPayoutPermissionWaiting,
    isSuccess: isPayoutPermissionCompleted,
    isError: isPayoutPermissionError,
    error: payoutPermissionTxError,
    data: payoutPermissionResp
  } = useWaitForTransaction({
    hash: payoutPermissionSentTx?.hash,
  });

  useEffect(() => {
    const error = payoutPermissionSentError || payoutPermissionTxError;
    if (error) {
      toast.error(extractErrorMessage(error.message));
      setLoader && setLoader(false);
    }
  }, [payoutPermissionSentError, payoutPermissionTxError]);

  return {
    // payoutPermission operation
    isPayoutPermissionSentError,
    payoutPermissionSentTx,
    isPayoutPermissionTxInProgress,
    isPayoutPermissionTxSent,
    payoutPermission,
    isPayoutPermissionCompleted,
    isPayoutPermissionError,
    isPayoutPermissionWaiting,
    payoutPermissionSentError,
    payoutPermissionTxError,
    payoutPermissionResp
  };
};

export default useSetPayoutPermission;
